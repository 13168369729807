.navbar-dark .navbar-nav .nav-link, .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, .75);
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 1);
}

.sidebar {
    min-width: 250px;
    max-width: 250px;
    min-height: calc(100vh - 56px);
    transition: all 0.3s;
}
.sidebar ul li a {
    display: block;
    padding: .75rem 1rem;
    color: rgba(255, 255, 255, .75);
    text-decoration: none;
}
.sidebar ul li a:hover, .sidebar ul .active a {
    color: rgba(255, 255, 255, 1);
    background: rgba(0, 0, 0, .25);
}
.sidebar ul ul a {
    padding-left: 2.5rem;
    background: rgba(0, 0, 0, .25);
}
.sidebar [data-toggle="collapse"] {
    position: relative;
}
.sidebar [data-toggle="collapse"]:before {
    content: "\f0d7";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 1rem;
}
.sidebar [aria-expanded="true"] {
    background: rgba(0, 0, 0, .25);
}
.sidebar [aria-expanded="true"]:before {
    content: "\f0d8";
}
.sidebar.toggled {
    margin-left: -250px;
}

.content {
    width: 100%;
}

.display-5 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
}

@media (max-width: 768px) {
    .sidebar {
        margin-left: -250px;
    }
    .sidebar.toggled {
        margin-left: 0;
    }
}